import React, { useState } from "react"

const PublicationToc = ({toc, current, setCurrent}) => {
  const [open, setOpen] = useState(false)

  if(!toc) return null

  const handleToggleClick = () => {
    setOpen(!open)
  }

  const handleClick = (newCurrent) => {
    setCurrent(newCurrent)
    handleToggleClick()
  }

  const classes = ['box']
  if(open) classes.push('toc-open')

  return <nav id="publication-toc" className={classes.join(' ')}>
    <button className="button toggle" onClick={ () => { handleToggleClick() } }>
      <div className="toggle-icon"></div>
    </button>
    <h2 className="box-title">
      <button className="nb" onClick={() =>{ handleClick(toc.slug) }}>{ toc.title }</button>
    </h2>
    <ul>
      { toc.sections.map(section => {
        return <li key={ section.slug } className={current === section.slug ? "current" : ""}>
          <h3><button className="nb" onClick={() => { handleClick(section.slug) }}>{ section.title }</button></h3>
          <ul>
            { section.publication_pages.map(page => {
              return <li key={page.slug} className={current === page.slug ? "current" : ""}>
                {current === page.slug ? 
                  <button className="nb" onClick={() => { handleToggleClick() }}>{page.title}</button> 
                : 
                  <button className="nb" onClick={() => { handleClick(page.slug) }}>{ page.title }</button>
                }
              </li>
            }) }
          </ul>
        </li>
      }) }
    </ul>
  </nav>
}

export default PublicationToc
