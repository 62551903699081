export const preparePublicationHtml = string => {
  if(!string) return ""

  // brackets to superscript
  string = string.replace(/\[/g,"<sup>").replace(/\]/g,"</sup>")

  // make external link open in new tab
  string = string.replace(/<a href="http/g,'<a target="_blank" rel="noopener noreferrer" href="http')

  return string
}

export const scrollToEndOfElement = (el) => {
  if(el) {
    const position = el.getBoundingClientRect().bottom - document.getElementById("site-header").getBoundingClientRect().bottom + window.pageYOffset;

    window.scrollTo({
        top: position,
        behavior: "auto"
    });
  }
}
