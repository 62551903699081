import React
//, { useRef, useEffect } 
from "react"

//import gsap, { Power1 } from 'gsap';

//import Dps from "../images/inline/dps.svg"
import Dpst from "../images/inline/dpst.svg"

const PublicationHeaderDps = () => {
  // let animationTrigger = useRef(null)

  // useEffect(()=> {
  //   const anmationTimeline = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: animationTrigger,
  //       start: "0% 80px",
  //       end: "100% top",
  //       toggleActions: "play pause reverse reset",
  //       //toggleActions: "play none none none",
  //       scrub: 0.25,
  //       //markers: {startColor: "green", endColor: "red", fontSize: "12px"}
  //       //pin: animationSvg,
  //     },
  //     ease: Power1.easeInOut
  //   })

  //   const animationKeyframes = [0.1, 0.2, 5]

  //   anmationTimeline.to(animationTrigger.querySelectorAll(".move-1"), { x: -500 }, animationKeyframes[0])
  //   anmationTimeline.to(animationTrigger.querySelectorAll(".move-2"), { x: 500 }, animationKeyframes[1])

  //   anmationTimeline.to(animationTrigger.querySelectorAll(".end"), { x: 0 }, animationKeyframes[2])
  // })

  return (
  <div id="publication-header" 
  //ref={el => animationTrigger = el}
  >
    <Dpst />
    {/* <h1>Digital<br/>Public Spaces</h1> */}
  </div>
  )
}

export default PublicationHeaderDps
