import React, { useEffect, useState } from "react"
import ReactHtmlParser from 'react-html-parser'

import { navigate } from "@reach/router"

import Seo from '../parts/seo'
import Layout from '../parts/layout'
import PublicationToc from '../parts/publicationToc'
import PublicationNavLinks from '../parts/publicationNavLinks'
import PublicationHeaderDps from "../parts/publicationHeaderDps"

import { preparePublicationHtml, scrollToEndOfElement } from "../util"


const PublicationTemplate = ({ pageContext, location }) => {
  const publication = pageContext.publication

  const [current, setCurrent] = useState(publication.slug)

  const baseSlug = `/${publication.slug}`
  const allPages = [baseSlug]
  publication.sections.forEach(section => {
    allPages.push(`${baseSlug}#${section.slug}`)

    section.publication_pages?.forEach(page => {
      allPages.push(`#${page.slug}`)
    })
  })

  useEffect(() => {
    if(location.hash) {
      setCurrent(location.hash.replace("#",""))
      scrollToEndOfElement(document.getElementById("publication-header"))
    }
  }, [location.hash]);


  const handleClick = (slug) => {
    setCurrent(slug)
    scrollToEndOfElement(document.getElementById("publication-header"))

    const newPath = (slug === publication.slug ? `${location.pathname}` : `${location.pathname}#${slug}`)
    navigate(newPath)
  }

  return (
    <Layout>
      <Seo title={publication.title} />

      { publication.slug === 'digital-public-spaces' && <PublicationHeaderDps /> }
      { publication.slug !== 'digital-public-spaces' && <div id="publication-header"></div> }

      <div id="publication-wrapper">
        <PublicationToc toc={publication} current={current} setCurrent={handleClick} />

        <div id="publication-main">
          <div id="publication-content">

            <div className={`publication-page ${(current === publication.slug ? "" : "hidden")}`}>
              <h1>{ publication.title }</h1>
              <div className="content">{ ReactHtmlParser(preparePublicationHtml(publication.introduction)) }</div>
              {/* { publication.sections && publication.sections.length > 1 &&
                <div className="publication-children">
                  <h4>Sections</h4>
                  { publication.sections.map(section => {
                    return <div key={section.slug} className="">
                      <h3><button className="nb" onClick={()=> { setCurrent(section.slug) }}>{ section.title }</button></h3>
                    </div>
                  }) }
                </div>
              } */}
              <PublicationNavLinks allPages={ allPages } page={ publication.slug } />
            </div>

            { publication.sections.map(section => {
              return <div key={section.slug}>
                <div className={`publication-page ${(current === section.slug ? "" : "hidden")}`}>
                  <h2 className="h1">{ section.title }</h2>
                  <div className="content">{ ReactHtmlParser(preparePublicationHtml(section.body)) }</div>

                  <PublicationNavLinks allPages={ allPages } page={ section.slug } />

                  { section.notes &&
                    <div id="notes" className="publication-extra publication-notes">
                      <h4>Notes</h4>
                      { ReactHtmlParser(preparePublicationHtml(section.notes)) }
                    </div>
                  }

                  { section.references &&
                    <div className="publication-extra publication-references">
                      <h4>References</h4>
                      { ReactHtmlParser(preparePublicationHtml(section.references)) }
                    </div>
                  }

                </div>
                { section.publication_pages.map(page => {
                  return <div key={ page.slug } className={`publication-page ${(current === page.slug ? "" : "hidden")}`}>
                    { page.author && <p className="publication-meta">{ page.author }</p> }
                    <h3 className="h1">{ page.title }</h3>
                    <div className="content">{ ReactHtmlParser(preparePublicationHtml(page.body)) }</div>
                    { page.page_parts.map(part => {
                      return <div key={ part.title }>
                        <h4 className="h2">{ part.title }</h4>
                        <div className="content">{ ReactHtmlParser(preparePublicationHtml(part.body)) }</div>
                      </div>
                    }) }

                    { page.links && page.links.length > 0 &&
                      <div className="publication-extra">
                        <h3>More resources</h3>
                        { page.links.map(link => {
                          return <div className="publication-link" key={ link.title }>
                            {/* { link.type && <div className="publication-meta">{ link.type.replace("_", " ") }</div>} */}
                            <h4>{ link.title }</h4>
                            { ReactHtmlParser(preparePublicationHtml(link.description)) }
                            <div className="buttons">
                              <a className="button" href={ link.url } target="_blank" rel="noopener noreferrer">
                                { link.buttonLabel }
                              </a>
                            </div>
                          </div>
                        }) }
                      </div>
                    }

                    <PublicationNavLinks allPages={ allPages } page={ page.slug } />

                    { page.notes &&
                      <div id="notes" className="publication-extra publication-notes">
                        <h4>Notes</h4>
                        { ReactHtmlParser(preparePublicationHtml(page.notes)) }
                      </div>
                    }

                    { page.references &&
                      <div className="publication-extra publication-references">
                        <h4>References</h4>
                        { ReactHtmlParser(preparePublicationHtml(page.references)) }
                      </div>
                    }

                  </div>
                }) }
              </div>

            }) }

          </div>
        </div>

      </div>
    </Layout>
  )
}

export default PublicationTemplate
