import React from "react"

const PublicationNavLinks = ({allPages, page}) => {
  var index = (allPages.findIndex(function(item){
      return item.indexOf(page)!==-1;
  }));

  const prev = (index > 0) ? allPages[index - 1] : null
  const next = (index < allPages.length - 1) ? allPages[index + 1] : null

  return <nav className="publication-extra publication-nav-links">
    { prev ? <a className="prev" href={prev}>previous page</a> : <span> </span> }
    { next ? <a className="next" href={next}>next page</a> : <span> </span> }
  </nav>
}

export default PublicationNavLinks
